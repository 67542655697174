import React, { useState, useEffect } from 'react';
import './contest.css';
import profile_logo from '../../image/Vector.png'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import like_share from '../../image/like_share.png'
import VideoPopup from './VideoPopup';
import ShareButton from '../share_button/share_button';

const Contest_page_1 = () => {

    const [showPopup, setShowPopup] = useState(false);
    const [videos, setVideos] = useState([])
    const [showPopup1, setShowPopup1] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [serverOtp, setServerOtp] = useState("");
    const [selectedVideo1, setSelectedVideo1] = useState(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [loading,setLoading] =  useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const videoId = params.get('id');
        if (videoId) {
            const fetchVideo = async () => {
                try {
                    const response = await axios.get(`  https://backend.clicktalksnow.com/get_standup_video_by_id/${videoId}`);
                    // Replace with your API URL
                    setSelectedVideo1(response.data.result[0]);
                } catch (error) {
                    console.error('Error fetching video details:', error);
                }
            };

            fetchVideo();
        }
        else {
            // If no videoId, clear selectedVideo1
            setSelectedVideo1(null);
        }

        // Update the current URL state
        setCurrentUrl(window.location.href);
    }, [window.location.search]);

    const handleReadMoreClick = (video) => {
        setSelectedVideo1(video);
        const params = new URLSearchParams(window.location.search);
        const updatedUrl = `${window.location.pathname}?${params.toString()}`;
        params.set('id', video.id); // Assuming video.id is the unique identifier
        window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
        setCurrentUrl(updatedUrl);
    };

    const handleClosePopup1 = () => {
        setSelectedVideo1(null);
        const params = new URLSearchParams(window.location.search);
        params.delete('id');
        const updatedUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
        setCurrentUrl(updatedUrl);
    };


    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        try {
            const response = await fetch('  https://backend.clicktalksnow.com/get_standup_voting_videos/?year=2024&year=2025');
            const data = await response.json();
            setVideos(data.result);
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
    };

    const [formData, setFormData] = useState({
        driveLink: '',
        teamName: '',
        clgName: '',
        description: '',
        category: '',
        teamEmail: '',
        phoneNumber: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const response = await fetch('https://script.google.com/macros/s/AKfycbwe9LnKmKtILR__ibkawBwejMRn2PCvu7Z20hdYCtTL_hO2ytIpCmwDBWiI9aSJZCE/exec', {
                method: 'POST',
                body: new URLSearchParams(formData)
            });
            const text = await response.text();
            alert(text);
            if (response.ok) {
                setFormData({
                    driveLink: '',
                    teamName: '',
                    clgName: '',
                    description: '',
                    category: '',
                    teamEmail: '',
                    phoneNumber: ''
                });
            }
            handleClosePopup();
        } catch (error) {
            alert('Error: ' + error);
        }finally {
            setLoading(false); // Set loading state back to false after the request finishes
        }
    };





    const handleParticipateClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleVoteNowClick = (video) => {
        setSelectedVideo(video);
        setShowPopup1(true);
        setOtpSent(false); // Reset OTP state
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };
    
    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };

    const handleSendOtp = async () => {
        try {
            setLoading(true);
            const response = await axios.post('  https://backend.clicktalksnow.com/send-otp/', { email });
            if (response.status === 200) {
                setOtpSent(true);
                setServerOtp(response.data.otp);
            }
        } catch (error) {
            alert("Error sending OTP. Please try again.");
        }finally {
            setLoading(false); // End loading
        }

    };

    const updateLikes = async (id) => {
        try {
            const response = await fetch(`  https://backend.clicktalksnow.com/standupvoting_videos/likes/${id}`, {
                method: 'PUT'
            });
            if (response.ok) {
                alert('OTP verified successfully. Vote counted!');
                handleClosePopup_vote();
                fetchVideos()
            }
            else {
                alert("Error verifying vote. Please try after sometime.")
            }
        } catch (error) {
            alert("Error verifying vote. Please try again.");
        }
    }


    const handleVerifyOtp = async () => {

        if(!name || !email || !phone){
            alert('Please fill all the fields!');
            return;
        }

        if (otp === serverOtp) {
            setLoading(true); 
            try {
                const verifyResponse = await fetch('  https://backend.clicktalksnow.com/standupverifyvotings/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: email, unique_id: selectedVideo.unique_id, name:name, phone:phone }),
                });
                const data = await verifyResponse.json();
                if (data.message) {
                    alert(data.message);
                    handleClosePopup_vote();
                } else {
                    updateLikes(selectedVideo.id);
                }
            } catch (error) {
                alert('Error verifying vote:', error);
            }
            finally {
                setLoading(false); // End loading
            }

        } else {
            alert('Invalid OTP. Please try again.');
        }
    };

    const handleClosePopup_vote = () => {
        setShowPopup1(false);
        setSelectedVideo(null);
        setName('');
        setEmail('');
        setPhone('');
        setOtp('');
    };


    return (
        <div>

            <div className='content-participate'>
                <h2>Creative Carnival Talent Hunt 2024-25</h2>

                <button className="participate-button" onClick={handleParticipateClick}>+ Participate</button>
            </div>
            <div className="gallery">
                {videos != undefined && videos.map(video => (
                    <div className="card" key={video.id}>

                        <div style={{ textAlign: 'left' }} className="video-title">

                            <div className='vedio_controls'><span><img src={profile_logo}></img></span>
                                <div>
                                    <p style={{ marginBottom: '1px' }}>{video.name}</p>
                                    <span style={{ color: '#888888', marginTop: '2px', fontSize: '14px' }}>{video.clg}</span>
                                </div></div>

                        </div>
                        <iframe
                            width="100%"
                            height="200"
                            src={`${video.youtube_path}`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={video.name}
                        ></iframe>
                        <div className="card-content">

                            <div className="likes">
                                <div className='sharing'>
                                    <div className="heart"><FontAwesomeIcon icon={faThumbsUp} /></div>
                                    <div>{video.likes}</div>
                                </div>
                                {/* <div><img src={like_share}></img></div> */}
                                <div style={{ cursor: 'pointer' }}><ShareButton url={`${currentUrl}&id=${video.id}`} title={'contest'} /></div>
                            </div>
                        </div>
                        <div className='line1'></div>
                        <div className='descrpt'>
                            <h3>The Explorer</h3>
                            <span>{video.descrpt.split(" ").slice(0, 20).join(" ") + (video.descrpt.split(" ").length > 20 ? "... " : " ")}</span>
                            <span className='read_me_more' onClick={() => handleReadMoreClick(video)}>Read more</span>
                        </div>
                        <div className='voting'><button className="vote-now-button" onClick={() => handleVoteNowClick(video)}>Vote Now</button></div>
                    </div>
                ))}
                {selectedVideo1 && <VideoPopup video={selectedVideo1} onClose={handleClosePopup1} />}
            </div>

            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={handleClosePopup}>&times;</span>
                        <h2>Participate in Creative Carnival Talent Hunt 2024-25</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="drive-link">Google Drive Link:</label>
                                <input type="url" id="drive-link" name="driveLink" value={formData.driveLink} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Team-Email">Team_email:</label>
                                <input type="email" id="Team-Email" name="teamEmail" value={formData.teamEmail} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="team-name">Team Name:</label>
                                <input type="text" id="team-name" name="teamName" value={formData.teamName} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Team-Email">Team_email:</label>
                                <input type="email" id="Team-Email" name="teamEmail" value={formData.teamEmail} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="clg-name">College Name:</label>
                                <input type="text" id="clg-name" name="clgName" value={formData.clgName} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone-number">Phone Number:</label>
                                <input type="tel" id="phone-number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required pattern="[0-9]{10}" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description:</label>
                                <textarea id="description" name="description" value={formData.description} onChange={handleChange} required></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="category">Category:</label>
                                <select id="category" name="category" value={formData.category} onChange={handleChange} required>
                                    <option value="">Select a category</option>
                                    <option value="dance">Dance</option>
                                    <option value="film">Film</option>
                                    <option value="photographer">Photographer</option>
                                    <option value="fashion">Fashion</option>
                                    <option value="standup">StandUp</option>
                                    <option value="singing">Singing</option>
                                    <option value="acting">Acting</option>
                                    <option value="otherarts">Other arts</option>
                                </select>
                            </div>
                            <button type="submit" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                </button>
                        </form>
                    </div>
                </div>
            )}
            {showPopup1 && selectedVideo && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={handleClosePopup_vote}>&times;</span>
                        <h2>Vote for {selectedVideo.name}</h2>
                        <form>
                            <div className="form-group">

                                <label htmlFor="name">Enter name:</label>
                                <input type="text" id="name" value={name} onChange={handleNameChange} required
                                disabled={loading} />

                                <label htmlFor="email">Enter email:</label>
                                <input type="email" id="email" value={email} onChange={handleEmailChange} required
                                disabled={loading} />

                                <label htmlFor="phone">Enter phone:</label>
                                <input type="tel" id="phone" value={phone} onChange={handlePhoneChange} required
                                disabled={loading} />

                            </div>
  
                            {!otpSent ? (
                                <button type="button" onClick={handleSendOtp} disabled={loading}>
                                {loading ? 'Sending OTP...' : 'Send OTP'}
                                </button>
                            ) : (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="otp">Enter OTP:</label>
                                        <input type="text" id="otp" value={otp} onChange={handleOtpChange} required 
                                         disabled={loading}/>
                                    </div>
                                    <button type="button" onClick={handleVerifyOtp} disabled={loading}>
                                    {loading ? 'Verifying OTP...' : 'Verify OTP'}
                                    </button>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Contest_page_1