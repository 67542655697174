import React, { useState, useEffect } from 'react';
import '../contest.css';
import profile_logo from '../../../image/Vector.png'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp ,faThumbsDown } from '@fortawesome/free-solid-svg-icons';

const Contest_page_2 = () => {

    const [showPopup2, setShowPopup2] = useState(false);
    const [videos, setVideos] = useState([])
    const [teamName, setTeamName] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [college, setcollege] = useState('');
    const [Description, setDescription] = useState('');

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        try {
            const response = await fetch('  https://backend.clicktalksnow.com/get_standup_voting_videos/');
            const data = await response.json();
            setVideos(data.result);
        } catch (error) {
            alert('Error fetching videos:', error);
        }
    };

    function generateEmbedUrl(url) {
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/;
      const match = url.match(regex);
      if (match) {
          const videoId = match[1];
          return `https://www.youtube.com/embed/${videoId}`;
      }
      return null; // Return null if the URL is not valid
    }

    const addVideo = async () => {
        
        try {

          const embedUrl = generateEmbedUrl(youtubeLink)
          
          const response = await fetch('  https://backend.clicktalksnow.com/upload_standup_voting_videos/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name:teamName, youtube_path: embedUrl , clg:college, description:Description}),
          });
          if (response.ok) {
            setTeamName('');
            setYoutubeLink('');
            setDescription('')
            setcollege('')
            alert('Video uploaded successfully!');
            fetchVideos(); 
            handleClosePopup2()
          } else {
            console.error('Error adding video');
          }
        } catch (error) {
          alert('Error adding video:', error);
        }
      };


      const handleDelete = async (id) => {
        try {
          const response = await fetch(`  https://backend.clicktalksnow.com/delete_standup_voting_video/${id}/`, {
            method: 'DELETE',
          });
    
          if (response.ok) {
            alert('Video deleted successfully!');
            fetchVideos(); // Refresh the video list after deletion
          } else {
            console.error('Error deleting video');
            alert('Error deleting video. Please try again.');
          }
        } catch (error) {
          console.error('Error deleting video:', error);
          alert('Error deleting video. Please try again.');
        }
      };

    const handleParticipateClick1 = () => {
        setShowPopup2(true);
    };

    const handleClosePopup2 = () => {
        setShowPopup2(false);
    };


    const handleSubmit1 = async (e) => {
        e.preventDefault();
        addVideo()
    };

    return (
        <div>
           
                <div className='content-participate'>
                    <h2>Admin</h2>
                        <button className="participate-button" onClick={handleParticipateClick1}>Upload</button>
                </div>
                <div className="gallery">
                    { videos!=undefined && videos.map(video => (
                        <div className="card" key={video.id}>

                            <div style={{ textAlign: 'left' }} className="video-title">
                                <div className='vedio_controls'><span><img src={profile_logo}></img></span>
                                <p>{video.name}</p></div>
                                <div><button onClick={() => handleDelete(video.id)} className="delete-button" >Delete</button></div>
                            </div>
                            <iframe
                                width="100%"
                                height="200"
                                src={`${video.youtube_path}`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title={video.name}
                            ></iframe>
                            <div className="card-content">

                                <div className="likes">
                                    <div className="heart"><FontAwesomeIcon icon={faThumbsUp}/></div>
                                    <div>{video.likes}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            
            {showPopup2 && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={handleClosePopup2}>&times;</span>
                        <h2>Admin Access</h2>
                        <form onSubmit={handleSubmit1}>
                            <div className="form-group">
                                <label htmlFor="Youtube-link">Youtube_link:</label>
                                <input type="url" id="youtube-link" 
                                onChange={(e) => setYoutubeLink(e.target.value)}
                                name="youtubelink" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="team-name">Team Name:</label>
                                <input type="text" id="team-name" name="teamName"
                                onChange={(e) => setTeamName(e.target.value)}
                                 required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Description">Description:</label>
                                <input type="text" id="Description" name="Description"
                                onChange={(e) => setDescription(e.target.value)}
                                 required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="college">College:</label>
                                <input type="text" id="college" name="college"
                                onChange={(e) => setcollege(e.target.value)}
                                 required />
                            </div>
                            
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Contest_page_2