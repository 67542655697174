import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import back_img from '../../images/Studio photographer-cuate 1 (1).png';
import logo1 from '../../images/footer-logo-2 3.png';
import './register.css';
import { useNavigate } from 'react-router-dom';
import { Link ,useLocation} from 'react-router-dom';
import benefitsImage from '../../image/Group 113.png';
import mask from '../../image/footer-logo-2 2.svg'


function RegistrationForm() {
    const location = useLocation();
    const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [isSearchResultsSidebarOpen, setSearchResultsSidebarOpen] = useState(false);
    const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
    const [emailMap, setEmailMap] = useState(new Map());
    const [emailAvailability, setEmailAvailability] = useState('');

    useEffect(() => {
        const fetchEmails = async () => {
            try {
                const response = await axios.get('https://backend.clicktalksnow.com/get_emails/');
                const emails = response.data.emails;
                const emailsMap = new Map(emails.map(email => [email.toLowerCase(), true]));
                setEmailMap(emailsMap);
            } catch (error) {
                console.error('Error fetching emails:', error);
            }
        };

        fetchEmails();
    }, []);

    useEffect(() => {
        // Disable right-click context menu
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
    
        // Disable common keyboard shortcuts for opening developer tools
        const handleKeyDown = (e) => {
          if (
            e.key === 'F12' ||
            (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
            (e.ctrlKey && e.key === 'U')
          ) {
            e.preventDefault();
          }
        };
    
        // Detect if developer tools are open and take action
        const handleDevToolsOpen = () => {
          const element = new Image();
          Object.defineProperty(element, 'id', {
            get: function () {
              alert('Developer tools are open!');
              window.location.replace('about:blank'); // Redirect to a blank page
            },
          });
          console.log(element);
        };
    
        // Add event listeners
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        handleDevToolsOpen();
    
        // Cleanup event listeners on component unmount
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    const toggleSidebar = () => {
        setMobileSidebarOpen(!isMobileSidebarOpen);
        if (!isMobileSidebarOpen) {
            setMobileNavbarOpen(false); // Close navbar if sidebar is opened
        }
    };

    const toggleNavbar = () => {
        setMobileNavbarOpen(!isMobileNavbarOpen);
        if (!isMobileNavbarOpen) {
            setMobileSidebarOpen(false); // Close sidebar if navbar is opened
        }
    };
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        age: '',
        yearofstudy: '',
        courses: '',
        password: '',
        confirmPassword: '',
        role: '',
        phone: '',
        city: '',
        agreement: false,
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [otp, setOtp] = useState('');
    const [sentOtp, setSentOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [step, setStep] = useState(1)
    const [sendOtpLoading, setSendOtpLoading] = useState(false);
    const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
    const [registerLoading, setRegisterLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
        if (name === 'email') {
            const lowerCaseEmail = value.toLowerCase();
            if (emailMap.has(lowerCaseEmail)) {
                setEmailAvailability('Email already present ! pls select another email');
            } else {
                setEmailAvailability('');
            }
        }
    };

    const handleSendOtp = async (e) => {
        try {
            setSendOtpLoading(true);
            e.preventDefault();
            const { name, email, age, yearofstudy, courses, role, phone, city, agreement } = formData;
            if (!name || !email || !age || !yearofstudy || !courses || !role || !phone || !city || !agreement) {
                alert('Please fill out all fields and agree to the terms.');
                return;
            }
            const response = await axios.post('  https://backend.clicktalksnow.com/send-otp/', { email: formData.email });
            setSentOtp(response.data.otp);
            setOtpSent(true);
            setStep(2)
            alert('otp sent sucessfuly')

        } catch (error) {
            alert('Error sending OTP', error);
        }
        finally {
            setSendOtpLoading(false); // Stop loading
        }
    };

    const handleVerifyOtp = () => {
        if (otp === sentOtp) {
            setOtpVerified(true);
            setOtpError('');

        } else {
            alert('Invalid OTP');
            console.error('Invalid OTP');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!otpVerified) {
            return;
        }

        const { name, email, age, yearofstudy, courses, password, city, role, phone } = formData;

        try {
            setRegisterLoading(true);
            const requestData = { name, email, age, yearofstudy, courses, password, city, role, phone };

            const response = await axios.post('  https://backend.clicktalksnow.com/registration/', requestData);


            if (response.ok === false) {
                alert('Failed to register');
            }

            const responseData = response.data;



            await axios.post(`  https://backend.clicktalksnow.com/upload_user_details_card/${responseData.unique_id}`);

            localStorage.setItem('isAuthenticated', 'true');
            localStorage.setItem('uniqueId', responseData.unique_id);
            localStorage.setItem('name', responseData.name);

            navigate('/membership-form'); 
        } catch (error) {
            alert('Error registering:', error.message);
        }finally {
            setRegisterLoading(false);
        }
    };

    return (
        <div className='sid-register'>
            <div className='full-body'>
                <div className="navbar">

                <div className="navbar-logo1">
            <img src={mask}></img>
            <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>                    <div className="navbar-links">

                 
<Link to="/shortlisted" className={location.pathname === '/shortlisted' ? 'active' : ''}>Shortlisted Participants</Link>
      <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
      <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

      
        <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
      
                    </div>
                    <div className="navbar-menu" onClick={toggleNavbar}>
                        &#9776; {/* Three dots icon for navbar */}
                    </div>
                    {isMobileNavbarOpen && (
                        <div className="mobile-navbar-links">
                             <Link to='/'>Home</Link>

            <Link to="/shortlisted" className={location.pathname === '/shortlisted' ? 'active' : ''}>Shortlisted Participants</Link>
      <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
      <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

     
        <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
                        </div>
                    )}
                </div>
                <div className="container">
                    <div className="form-container">
                        <h2>Registration Form</h2>
                        <p>Kindly fill in your details to register</p>
                        <form onSubmit={handleSubmit}>
                            <>
                                <label htmlFor="name">Your fullname</label>
                                <input type="text" id="name" name="name" placeholder="Enter your name" required
                                    value={formData.name}
                                    onChange={handleChange} />
                                      
                                <label htmlFor="email">Email address</label>
                                <input type="email" id="email" name="email" placeholder="Enter email address" required
                                    value={formData.email}
                                    onChange={handleChange}
                                    disabled={otpSent} />
                                    {emailAvailability && <p className="email-error" style={{ color: 'red', fontSize: '12px' }}>{emailAvailability}</p>}

                                <label htmlFor="age">Your age</label>
                                <input type="number" id="age" name="age" placeholder="Enter your age" required min={0}
                                    value={formData.age}
                                    onChange={handleChange} />

                                <label htmlFor="yearofstudy">Year of study</label>
                                <input type="number" id="yearofstudy" name="yearofstudy" placeholder="Enter your year of study" required min={0}
                                    value={formData.yearofstudy}
                                    onChange={handleChange} />

                                <label htmlFor="courses">Courses</label>
                                <input type="test" id="courses" name="courses" placeholder="Enter courses" required
                                    value={formData.courses}
                                    onChange={handleChange} />

                                <label htmlFor="role">Role</label>
                                <select
                                    id="role"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    required>
                                    <option value="">Select Role</option>
                                    <option value="actor">Actor</option>
                                    <option value="animator">Animator</option>
                                    <option value="dancer">Dancer</option>
                                    <option value="cinematographer">Cinematographer</option>
                                    <option value="photographer">Photographer</option>
                                    <option value="fashion aspirant">Fashion Aspirant</option>
                                    <option value="gamer">Gamer</option>
                                    <option value="Short film maker">Short film maker</option>
                                    <option value="visual artists">Visual Artists</option>
                                    <option value="singer">Singer</option>
                                    <option value="stand-up comedian">Stand-up Comedian</option>
                                </select>

                                <label htmlFor="phone">Phone number</label>
                                <input type="tel" id="phone" name="phone" placeholder="Enter Phone number"
                                    required
                                    value={formData.phone}
                                    onChange={handleChange} />
                                

                                <label htmlFor="city">City</label>
                                {/* <input type="text" id="city" name="city" placeholder="Enter city"
                                    value={formData.city}
                                    onChange={handleChange} /> */}
                                <select
                                    id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required>
                                    <option value="">Select City</option>
                                    <option value="Hyderabad">Hyderabad</option>
                                    <option value="Vijayawada">Vijayawada</option>
                                    <option value="Warangal">Warangal</option>
                                    <option value="Karimnagar">Karimnagar</option>
                                </select>
                                <div className="checkbox-container">
                                    <input type="checkbox" id="agreement" name="agreement"
                                        checked={formData.agreement}
                                        onChange={handleChange} />
                                    <label htmlFor="agreement" style={{margin:'0px'}}>I agree to become a member in the Click Talks family</label>
                                </div>

                                {step === 1 && (
                                   <button onClick={handleSendOtp} disabled={otpSent || sendOtpLoading}>
                                   {sendOtpLoading ? 'Sending OTP...' : 'Send OTP to email'}
                               </button>)}
                                {otpSent && !otpVerified && (
                                    <div className="otp-container">
                                        <input type="text" id="otp" name="otp" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                                        <button onClick={handleVerifyOtp} disabled={verifyOtpLoading || registerLoading}>
                                        {verifyOtpLoading ? 'Verifying...' : 'Verify'}
                                    </button>
                                    <button onClick={handleSendOtp} disabled={sendOtpLoading || registerLoading}>
                                        {sendOtpLoading ? 'Resending...' : 'Resend OTP'}
                                    </button>
                                    </div>
                                )}
                                {otpVerified && (
                                    <>
                                        <label htmlFor="password">Enter Password</label>
                                        <div className="password-container">
                                            <input
                                                type={passwordShown ? 'text' : 'password'}
                                                id="password"
                                                name="password"
                                                placeholder="Enter Password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                            />
                                            <FontAwesomeIcon
                                                icon={passwordShown ? faEye : faEyeSlash}
                                                onClick={togglePasswordVisibility}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <div className="password-container">
                                            <input
                                                type={passwordShown ? 'text' : 'password'}
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                placeholder="Confirm Password"
                                                value={formData.confirmPassword}
                                                onChange={handleChange}
                                                required
                                            />
                                            <FontAwesomeIcon
                                                icon={passwordShown ? faEye : faEyeSlash}
                                                onClick={togglePasswordVisibility}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>

                                        <button disabled={registerLoading}>
                                        {registerLoading ? 'Registering...' : 'Register'}
                                    </button>
                                    </>
                                )}
                            </>

                        </form>
                        <div className="login-link">
                            <p>Already have an account? <Link to='/login' style={{color:'black',fontWeight:'600' , textDecoration:'none'}}>Please login</Link></p>
                        </div>
                    </div>
                    <div className="benefits-container">
                        <img src={benefitsImage} alt="Membership Benefits" className="benefits-image" />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default RegistrationForm;
